.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0px;
}

.canvas-container {
  position: relative;
}

.canvas {
  max-width: 100%;
  height: auto;
}

.pixel {
  position: absolute;
  width: 1px;
  height: 1px;
  background-color: red;
}

.map-icon {
  color: #ff0000;
  /* Set the icon color to red */
  font-size: 15px;
  /* Set the icon size */
}

.carousel-image {
  width: 100%;
  /* 设置容器宽度，根据实际情况调整 */
  height: 300px;
  /* 设置容器高度，根据实际情况调整 */
  overflow: hidden;
  /* 隐藏超出容器尺寸的内容 */
  transform: scale(0.85);
  object-fit: contain;
}

.carousel-image img {
  width: 100%;
  /* 图片宽度设置为100%，自动适应容器宽度 */
  height: auto;
  /* 图片高度自适应，保持宽高比例 */
  object-fit: contain;
  /* 控制图片在容器中的填充方式，可以根据需求使用其他值，如 cover 或 fill */
}

.scrollable-content {
  height: 300px;
  overflow-y: scroll;
}

.scrollable-content::-webkit-scrollbar {
  width: 6px;
  /* 调整滚动轮的宽度 */
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #888;
  /* 设置滚动轮的颜色 */
  border-radius: 3px;
  /* 设置滚动轮的圆角 */
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 设置滚动轮在悬停时的颜色 */
}

.text-container {
  text-indent: 4ch;
  white-space: pre-wrap;
}

.text-container::first-letter {
  text-indent: 0;
}

.audio-player {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  /* 或适当的高度，根据需要调整 */
}

.play-icon {
  font-size: 48px;
  /* 根据需要调整图标的大小 */
  color: #000;
  /* 根据需要调整图标的颜色 */
  cursor: pointer;
}

.video-player {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  /* 或适当的高度，根据需要调整 */
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 宽高比的百分比值 */
}

.video-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-carousel {
  position: relative;
}

.custom-carousel .slick-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
}

.custom-carousel .slick-dots li button {
  background-color: blue;
  /* 设置面板指示点的背景颜色 */
  width: 15px;
  /* 设置面板指示点的宽度 */
  height: 10px;
  /* 设置面板指示点的高度 */
}

.custom-carousel .slick-dots .slick-active button {
  background-color: black !important;
  /* 设置面板指示点的背景颜色 */
}

.my-modal {

  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
}

.ant-tabs-tab-active {
  background-color: rgb(181, 179, 179) !important;
  /* 激活状态的背景颜色 */
}
